import "./style.scss"; // Imported here
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import { Metric, reportWebVitals } from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// reportWebVitals((metric: Metric) => {
//   console.log(metric);

// });
