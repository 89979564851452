import React, { useState, useEffect }  from 'react';
import { useNavigate } from 'react-router-dom';
import './profile_main.scss';
import avatar from '../../assets/Mia_logo1.jpg';
import { usePsyfyClient } from "../../hooks/psyfyClient";

interface CreditTabProps {
  credits: number | null;
}

const CreditsTab: React.FC<CreditTabProps> = ({ credits }) => {
  return (
    <div className="credits-tab">
      <p className="font">Your Credits: {credits !== null ? credits : 'Loading...'}</p>
    </div>
  );
};


const ProfilePage = () => {
  const navigate = useNavigate();
  const { fetchUserCredits, deleteAccount } = usePsyfyClient();
  const userEmail = localStorage.getItem("userEmail");
  const [userCredits, setUserCredits] = useState<number | null>(null);
  const [deletingAccount, setDeletingAccount] = useState(false);

  const handleConversationHistoryClick = () => {
    // Navigate to the Conversation History page
    navigate('/conversation');
  };

  const handleConversationSummaryClick = () => {
    // Navigate to the Summary page
    navigate('/summary');
  };

  const handleSubscription = () => {
    // Navigate to the Summary page
    navigate('/credits');
  };

  useEffect(() => {
    if (!userEmail) {
      navigate("/login"); // Redirect to login if no userEmail found.
    }
  }, [navigate, userEmail]);


  useEffect(() => {
    const updateCredits = async () => {
      const credits = await fetchUserCredits();
      setUserCredits(credits);
    };

    if (userEmail) {
      updateCredits();
    }
  }, [userEmail, fetchUserCredits]);

  const handleDeleteAccount = async () => {
    if (!userEmail) {
      alert("User email not found. Please log in.");
      return;
    }
  
    const confirmed = window.confirm("Are you sure you want to delete your account? This action cannot be undone.");
    if (!confirmed) return;
  
    setDeletingAccount(true);
  
    const accessToken = localStorage.getItem("access_token");
  
    try {
      const data = await deleteAccount(accessToken!); // Call the extracted function
      alert(data.message);
  
      // Optionally, redirect the user to the login page after account deletion
      localStorage.clear(); // Clear any saved user data
      navigate("/login");
    } catch (error) {
      alert("There was an issue deleting your account. Please try again.");
    } finally {
      setDeletingAccount(false);
    }
  };
  

  return (
    <div className="profile-page">
      <div className="left-column">
        <div className="user-avatar">
        <img src={avatar} alt="User Avatar" />

       
        <div className="user-info">
          <p>Email: {userEmail}</p> 
          <CreditsTab credits={userCredits} /> 

        </div>
       
        <div className="button-container">
        <button className="profile-btn" onClick={handleSubscription}>
          Manage Subscription
        </button>
  

        
        <button className="profile-btn" onClick={handleConversationHistoryClick}>
          Conversation History
        </button>

        <button className="profile-btn" onClick={handleConversationSummaryClick}>
          Conversation Summary
        </button>

        <button
            className="delete-account-btn"
            onClick={handleDeleteAccount}
            disabled={deletingAccount}
          >
            {deletingAccount ? "Deleting Account..." : "Delete Account"}
          </button>
          
      </div>
      </div>
      </div>

      <div className="right-column">
      
      <div className="graph-container-text">
      <p>Mood Profile (coming soon ...) </p>
      </div>
      <div className="graph-container">
      </div>
    </div>
    </div>
  );
};

export default ProfilePage;
