import React, { useEffect } from 'react';
import IntroductionText from "../IntroductionText";
import "./intro_style.scss";

const Intro: React.FC = () => {
  useEffect(() => {
    
    document.body.classList.add("research-page-body");


    return () => {
      document.body.classList.remove("research-page-body");
    };
  }, []);  
  return (
    <>
    
    <div className="research-style">
      <div className="intro-container">
        <IntroductionText
          title="WHAT IS PSYFY"
          content={
            <p>
              Psyfy is mental wellness chatbot that provides self-help guidance
              to people with anxiety issues. Our team is dedicated to creating
              innovative solutions to address the growing challenge of anxiety
              disorders among U.S adults, a population that currently stands at
              77.4 million. We understand that seeking professional help is the
              most effective way to manage anxiety and depression symptoms, but
              cost barriers often hinder access to treatment. Consequently,
              we've developed Psyfy, a state-of-the-art mental wellness chatbot
              powered by a generative model. Psyfy offers trustworthy self-help
              guidance to individuals dealing with anxiety-related problems,
              providing an affordable and accessible avenue for mental health
              support.{" "}
            </p>
          }
        />
        <IntroductionText
          title="WHO ARE WE"
          content={
            <>
              <p style={{ marginBottom: "20px" }}>
                Our team comprises multidisciplinary researchers focusing on the
                fields of machine learning, large language models, and the
                systematic study of diagnosing, analyzing, and offering
                solutions to emotional disorders. We blend our expertise in
                emotional regulation with our vision for research that benefits
                health and wellbeing. Our founding members,{" "}
                <a className="social-link" href="https://luciasalar.github.io/">
                  Lucia Chen
                </a>{" "}
                and{" "}
                <a
                  className="social-link"
                  href="https://scholar.google.com/citations?user=ONNif60AAAAJ&hl=en&oi=ao"
                >
                  Ben Krause{" "}
                </a>
                and our consultant{" "}
                <a
                  className="social-link"
                  href="https://scholar.google.com/citations?user=L9XwcpcAAAAJ&hl=en&oi=ao"
                >
                  David Preece
                </a>
                , are dedicated to creating digital tools that help those who
                cannot afford healthcare.
              </p>
            </>
          }
        />
      </div>

      </div>
    </>
  );
};

export default Intro;
