import React from "react";
import LoginForm from "./LoginForm";
import "./login.scss";

const Login = () => {
  return (
    


        <div className="login-page-container">
        <div className="form-and-chat">
          <LoginForm />
        </div>
      </div>


  );
};

export default Login;
