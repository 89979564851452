import React, { useState, useRef, useEffect} from "react";
import './support.scss'
import {usePsyfyClient} from "../../hooks/psyfyClient";

const Support = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [feedback, setFeedback] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const {submitSupportRequest} = usePsyfyClient();
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    if (userEmail) {
      setEmail(userEmail);
    }
  }, []);




  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email || !message) {
      setFeedback("Please fill in all fields.");
      return;
    }

    const responseMessage = await submitSupportRequest(email, message); // Use the function here
    setFeedback(responseMessage);
  };




  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset the height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scroll height
    }
    setMessage(e.target.value);
  };

  return (
    <div className="support-page">
    <div className="support-background">
    <div className="support-container">
      <h2>Support</h2>
      <p>If you are experiencing any issues or have feedback for Psyfy, please share the details below.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          {/* <label>Email:</label> */}
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email"
          />
        </div>
        <div className="form-group">
          {/* <label>Describe your issue:</label> */}
          <textarea
            className="form-control"
            value={message}
            // onChange={(e) => setMessage(e.target.value)}
            onChange={handleInput}
            placeholder="Describe your issue"
            rows={10}  
            required
          ></textarea>
        </div>
        <button disabled={loading} type="submit" className="btn btn-primary">
          {/* Submit */}
          {loading ? "Submitting..." : "Submit"} 
        </button>

      </form>
      {feedback && <p className="feedback">{feedback}</p>}
    </div>
    </div>
    </div>
  );
};

export default Support;
