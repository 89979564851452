import React, { useState} from "react";
// import logo from "../../assets/Mia_logo1.jpg";
import { sendState } from "../../hooks/chatbotService";
import Linkify from 'react-linkify';
import { parseISO, format, isValid, parse } from 'date-fns';
import Logger from '../../utils/logger';

const MessageItem = ({
  message,
  onRating,
  onRewriteInitiate,
  isExperiment,
  compactMode,
}) => {
  const [isRewriting, setIsRewriting] = useState(false);
  const [rewrittenText, setRewrittenText] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [rewriteSubmitted, setRewriteSubmitted] = useState(false);
 

  // Check if there's actual content to display
  const hasContentToShow =
    message.originalText?.trim() ||
    message.text?.trim() ||
    (rewriteSubmitted && rewrittenText.trim());

  // Prevent rendering if there is no content
  if (!hasContentToShow) {
    return null;
  }



  const handleRewriteChange = (e) => {
    setRewrittenText(e.target.value);
  };

  const handleRewriteSubmit = () => {
    onRewriteInitiate(message.id, rewrittenText);
    setIsRewriting(false);

    setRewriteSubmitted(true);
  };

  const toggleRewrite = () => {
    setIsRewriting(!isRewriting);
    setRewrittenText(message.text);
  };

  // select state function
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleStateSubmit = async () => {
    if (selectedState) {
      await sendState(selectedState, message.id);
      // Optionally, do something after the state has been successfully sent, like notifying the user
    } else {
      alert("Please select a state before submitting.");
    }
  };

  const formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });


  
  const formatTimestamp = (isoString) => {
    if (!isoString) {
      Logger.error('Received undefined or null timestamp');
      return 'Invalid timestamp';
    }
  
    try {
      let date;
  
      if (isoString.includes('T')) {
        // ISO format
        date = parseISO(isoString);
      } else if (/^\d{1,2}:\d{2}:\d{2}\s*(AM|PM)$/i.test(isoString)) {
        // Time-only format
        const currentDate = format(new Date(), 'yyyy-MM-dd');
        const dateTimeString = `${currentDate} ${isoString.replace(/\u202F|\u00A0/g, ' ')}`;
        date = parse(dateTimeString, 'yyyy-MM-dd h:mm:ss a', new Date());
      } else if (/^\d{1,2}\/\d{1,2}\/\d{4}, \d{2}:\d{2}:\d{2}$/.test(isoString)) {
        // New format M/D/YYYY, HH:MM:SS or MM/DD/YYYY, HH:MM:SS
        date = parse(isoString, 'M/d/yyyy, HH:mm:ss', new Date());
        if (!isValid(date)) {
          date = parse(isoString, 'MM/dd/yyyy, HH:mm:ss', new Date());
        }
      } else if (/^\d{4}-\d{2}-\d{2}$/.test(isoString)) {
        // Date only format YYYY-MM-DD
        date = parse(isoString, 'yyyy-MM-dd', new Date());
      } else {
        throw new Error('Unrecognized date or time format');
      }
  
      if (isValid(date)) {
        return format(date, 'MMM dd, yyyy, h:mm:ss a');
      } else {
        throw new Error('Invalid date value');
      }
    } catch (error) {
      console.error('Error formatting timestamp:', isoString, error);
      Logger.error('Error formatting timestamp:', isoString, error);
      return 'Invalid timestamp';
    }
  };
  


  return (
    <div className={`msg ${message.type === "bot" ? "left-msg" : "right-msg"}`}>
      {/* <div
        className="msg-img"
        style={{
          backgroundImage: `url(${message.type === "bot" ? logo : "logo"})`,
        }}
      ></div> */}
      {/* <div className="msg-bubble"> */}
      <div className={`msg-bubble ${compactMode ? "compact" : ""}`}>
        <div className="msg-info">
          <div className="msg-info-name">{message.author}</div>
          {/* <div className="msg-info-time">{message.timestamp}</div> */}
          <div className="msg-info-time">
            {/* {formatTimestamp(message.timestamp)} */}
            {message.timestamp ? formatTimestamp(message.timestamp) : 'No timestamp available'}
          </div>
          {/* Rating buttons (for bot messages) */}
        </div>

        {message.originalText && (
          <div className="msg-original-text">{message.originalText}</div>
        )}

        <div>
        <Linkify>
          {message.originalText ||
            message.text.split(/(?:\r\n|\r|\n)/g).map((text, index, array) => (
              <React.Fragment key={index}>
                {text}
                {index < array.length - 1 && <br />}
              </React.Fragment>
            ))}

       </Linkify>

          {rewriteSubmitted ? (
            // If yes, display the submitted rewritten text with the "Rewrite:" label
            <>
              <strong>Rewrite:</strong> {rewrittenText}
              <br />
            </>
          ) : // If the rewrite has not been submitted, check if the user is currently rewriting or displaying the original message
          isRewriting && isExperiment ? (
            <>
              <textarea value={rewrittenText} onChange={handleRewriteChange} />
              <button onClick={handleRewriteSubmit}>Submit</button>
            </>
          ) : null}

          {isExperiment && (
            <div className="msg-actions">
              <button onClick={toggleRewrite}>
                {isRewriting ? "Cancel" : "Rewrite"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
