import dotenv from "dotenv";
dotenv.config();

const CONFIG = {
  BACKUP_URL: "https://psyfy.xyz",

  BASE_URL: "https://psyfy.xyz",

  SECRET_KEY: process.env.REACT_APP_SECRET_KEY,
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_PUBLIC,
};
export default CONFIG;

