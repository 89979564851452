import React from "react";
import "../css/intro_style.scss";

interface IntroductionTextProps {
  title: string;
  content: string | JSX.Element;
}

const ResourceBankText: React.FC<IntroductionTextProps> = ({
  title,
  content,
}) => {
  return (
    <section className="resource-page">
      <h1 className="subpage-heading">{title}</h1>
      <p>{content}</p>
    </section>
  );
};

export default ResourceBankText;
