import React from "react";
import Header from "./header";
import Footer from "./footer/Footer";
import StandardRoutes from "./StandardRoutes";
import "../css/standard-page.scss"
// Check if the current route is /chatbot
import { useLocation } from "react-router-dom";


const StandardPage = () => {
    const location = useLocation();
    const hideFooter = location.pathname === "/chatbot";

    return (
        <div className="standard-page">
            <div className="header">
                <Header />
            </div>
            <div className="content">
                <StandardRoutes />
                <div className="footer">
                {!hideFooter && (
                    <div className="footer">
                        <Footer />
                    </div>
                )}
    
                </div>
            </div>
        </div>
    );
};

export default StandardPage;