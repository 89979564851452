import React from "react";
import ChatButton from "./ChatButton";
import face from "../../assets/face.jpg";

const MainTitle = () => {
  const titlePart1 = "PSY";
  const titlePart2 = "FY";

  return (
    <div className="main-container">
      <div className="inner-header">
        <img src={face} alt="face" className="face-photo" />
        <div className="title-container">
          <h2 id="maintitle">
            {titlePart1}
            {titlePart2}
          </h2>
          <h3 id="subtitle">Psychology For You</h3>
          <div className="chatButton-container-main">
            <ChatButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainTitle;
