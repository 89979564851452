// SpinningCircle.js
import React, { useEffect, useState } from 'react';
import '../css/SpinningCircle.scss';

function SpinningCircle({ isSpeaking, volume }) {
  const [showStartSpeaking, setShowStartSpeaking] = useState(true);
  const [showPressToStop, setShowPressToStop] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowStartSpeaking(false);
  
    }, 2000); // Show "Start speaking" for 2 seconds
    return () => clearTimeout(timer);
  }, [isSpeaking]);

  useEffect(() => {
    if (!showStartSpeaking && isSpeaking) {
      setShowPressToStop(true);  // Show "Press to stop" when speaking starts
    } else if (!isSpeaking) {
      setShowPressToStop(false);  // Hide "Press to stop" when speaking stops
    }
  }, [isSpeaking, showStartSpeaking]);


  return (
    <div className="spinning-circle-container">
      <div className="spinning-circle"></div>
      {showStartSpeaking && <div className="start-speaking">Start speaking..</div>}
      {showPressToStop &&  <div className="start-speaking">Press to stop</div>}
      <div className="volume-meter">
        <div
          className="volume-level"
          style={{ height: `${Math.min(volume, 100)}%` }}
        ></div>
      </div>
    </div>
  );
}

export default SpinningCircle;
