import React, { useState, useEffect } from 'react';
import '../css/unsubscribe.scss'
import {usePsyfyClient} from "../hooks/psyfyClient";

function UnsubscribePage() {
    const {handleUnsubscribe} = usePsyfyClient();
    const [reasons, setReasons] = useState({
        tooManyEmails: false,
        notInterested: false,
        contentNotUseful: false,
        tooExpensive: false,
        technicalIssues: false,
        foundAlternative: false,
        privacyConcerns: false,
        otherCheckbox: false,
        otherText: ''
    });

    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const email = searchParams.get('email');
        if (email) {
            setUserEmail(email);
        }
    }, []);

    const onUnsubscribeClick = () => {
        handleUnsubscribe(userEmail, reasons);
    };


    const handleReasonChange = (event) => {
        const { name, type, checked, value } = event.target;
        setReasons({
            ...reasons,
            [name]: type === 'checkbox' ? checked : value
        });
    };

 

    return (
        <div className="body-container">
            <div className="unsubscribe-container">
                <h1>Manage Your Subscription</h1>
                <p>Please let us know why you are unsubscribing. Your feedback is important to us and will help us improve our service.</p>
                <form className="unsubscribe-form">
                    <label>
                        <input
                            type="checkbox"
                            name="tooManyEmails"
                            checked={reasons.tooManyEmails}
                            onChange={handleReasonChange}
                        />
                        I receive too many emails.
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="notInterested"
                            checked={reasons.notInterested}
                            onChange={handleReasonChange}
                        />
                        The content is no longer relevant to my interests.
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="contentNotUseful"
                            checked={reasons.contentNotUseful}
                            onChange={handleReasonChange}
                        />
                        The information provided is not useful.
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="tooExpensive"
                            checked={reasons.tooExpensive}
                            onChange={handleReasonChange}
                        />
                        The service is too expensive.
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="technicalIssues"
                            checked={reasons.technicalIssues}
                            onChange={handleReasonChange}
                        />
                        I experience technical difficulties with the service.
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="foundAlternative"
                            checked={reasons.foundAlternative}
                            onChange={handleReasonChange}
                        />
                        I have found an alternative that better meets my needs.
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="privacyConcerns"
                            checked={reasons.privacyConcerns}
                            onChange={handleReasonChange}
                        />
                        I am concerned about my privacy.
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            name="otherCheckbox"
                            checked={Boolean(reasons.other)}
                            onChange={(e) => setReasons({...reasons, otherCheckbox: e.target.checked, other: e.target.checked ? reasons.other : ''})}
                        />
                        Other (Please specify):
                    </label>
                    {reasons.otherCheckbox && (
                        <input
                            type="text"
                            name="otherText"
                            value={reasons.otherText}
                            onChange={handleReasonChange}
                            placeholder="Please specify your reason"
                            className="other-reason-input" // Use this class to style the input box
                        />
                    )}
                    <button type="button" onClick={onUnsubscribeClick}>Unsubscribe</button>
                </form>
            </div>
        </div>
    );
}

export default UnsubscribePage;
