import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { usePsyfyClient } from "../../hooks/psyfyClient";
import { useLocation } from 'react-router-dom'; 
import './payment.scss';
import Logger from "../../utils/logger";

interface IAddress {
  line1: string;
  city: string;
  state: string;
  postal_code: string;
}

interface IBillingDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: IAddress;
}

const LoadingPopup = () => (
  <div className="loading-backdrop">
    <div className="loading-popup">
      <div className="loading-content">
        <p>Payment is being processed, please wait...</p>
      </div>
    </div>
  </div>
);


const StripeCheckoutComponent: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation(); // Access location here


 const { selectedTab = { plan: "", price: "0$", tokens: 0 } } = location.state || {};



  const { handlePayment } = usePsyfyClient();
  const [billingDetails, setBillingDetails] = useState<IBillingDetails>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: {
      line1: '',
      city: '',
      state: '',
      postal_code: ''
    }
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name.includes('.')) {
      const parts = name.split('.');
      setBillingDetails(prev => ({
        ...prev,
        address: {
          ...prev.address,
          [parts[1]]: value
        }
      }));
    } else {
      setBillingDetails(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        color: '#ffffff', // White text color
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#999999' // Placeholder text color
        }
      },
      invalid: {
        color: '#fa755a', // Color when the input is invalid
        iconColor: '#fa755a'
      }
    }
  };



  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

  
    if (!stripe || !elements) {
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      setIsButtonDisabled(true);
      setLoading(true);
      try {
        const billingDetailsForStripe = {
          name: `${billingDetails.firstName} ${billingDetails.lastName}`,
          email: billingDetails.email,
          phone: billingDetails.phone,
          address: {
            line1: billingDetails.address.line1,
            city: billingDetails.address.city,
            state: billingDetails.address.state,
            postal_code: billingDetails.address.postal_code,
          }
        };
  
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: billingDetailsForStripe
        });
  
        if (error) {
          Logger.error('Stripe error:', error.message);
          setIsButtonDisabled(true);
          setLoading(true);
        } else if (paymentMethod) {
          Logger.debug('PaymentMethod:', paymentMethod);
          // Call handlePayment if paymentMethod creation was successful
          await handlePayment({
            paymentMethodId: paymentMethod.id,
            price: selectedTab.price, // Ensure this value is defined
            plan: selectedTab.plan
          });
        }
      } catch (error) {
        Logger.error('Payment error:', error);
        setIsButtonDisabled(true);
        setLoading(true);
      }
    }
  };
  


  return (

    <div className="login-page-container">
 


    <div className="payment-form-container">
    <div className="credit-summary">
          <p>Purchase Plan: {selectedTab.plan}</p>
          <p>Purchase Credits: {selectedTab.credits}</p>
          <p>Price: {selectedTab.price}</p>
    </div>
      <form onSubmit={handleSubmit}>
        <div className="card-details">
         

          <div className="name-group">
            <input type="text" name="firstName" placeholder="First Name" onChange={handleInputChange} required />
            <input type="text" name="lastName" placeholder="Last Name" onChange={handleInputChange} required />
          </div>
          <div className="card-element">
         <CardElement options={cardElementOptions} />
         </div>
        </div>
        <div className="billing-address">
          <input type="text" name="address.line1" placeholder="Street Address" onChange={handleInputChange} required />
          <input type="text" name="address.city" placeholder="City" onChange={handleInputChange} required />
          <input type="text" name="address.state" placeholder="State/Region" onChange={handleInputChange} required />
          <input type="text" name="address.postal_code" placeholder="ZIP/Postal Code" onChange={handleInputChange} required />
        </div>
      
        <button type="submit"  disabled={isButtonDisabled}>Pay</button>
        
   
      </form>
    </div>
    </div>
  );
};

export default StripeCheckoutComponent;
