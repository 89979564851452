import React, { useState, useEffect, ChangeEvent} from "react";
import Header from "../header/Header";
import axios from "axios";
import CONFIG from "../../config";
import { useNavigate } from "react-router-dom";
import { usePsyfyClient } from "../../hooks/psyfyClient";
import Switch from '@mui/material/Switch';
import Logger from "../../utils/logger";
import "../../css/constants.scss"



// Define a type for the tab state
type TabState = {
  plan: string;
  price: string;
  tokens: number;
  isSubscribed: boolean; 
  currentPlan: string;
  isNewPlanSelected: boolean;
};

// Define a type for credit plan details
type CreditPlanDetails = {
  plan: string;
  price: string;
  tokens: number;
};

const BuyCredit = () => {
  
 
  const [selectedTab, setSelectedTab] = useState<TabState>({
    plan: '',
    price: '',
    tokens: 0,
    isSubscribed: false,
    currentPlan: '',
    isNewPlanSelected: false
  }); 
  const navigate = useNavigate(); // Use navigate for navigation
  const userEmail = localStorage.getItem("userEmail");
  const { fetchSubscription, fetchUserCredits, fetchUserSubscription_ios} = usePsyfyClient();
  const [autoRenew, setAutoRenew] = useState(false);
  const [userCredits, setUserCredits] = useState<number | null>(null);
  const planMapping: { [key: string]: string } = {
    'Basic': 'ai.psyfy.basic',
    'Plus': 'ai.psyfy.plus',
    'Premium': 'ai.psyfy.premium',
    'Free': 'ai.psyfy.free',
  };
  const [isIOSSubscription, setIsIOSSubscription] = useState(false);


  const fetchiosSubscription = async (userEmail: string) => {
    try {
        const subscriptionData = await fetchUserSubscription_ios(userEmail);

        const latestSubscription = subscriptionData?.latest_subscription;

        if (latestSubscription?.platform === "ios" && latestSubscription?.credit_plan !== "ai.psyfy.free") {
            setIsIOSSubscription(true);
        }
    } catch (error) {
        Logger.error('Failed to fetch subscription:', error);
    }
};
 

  // const handleTabClick = (plan: string, price: string, tokens: number) => {
  //   setSelectedTab(prev => ({ ...prev, plan, price, tokens, isNewPlanSelected: plan !== prev.currentPlan }));
  // };
  const handleTabClick = async (plan: string, price: string, tokens: number) => {
    if (plan === 'Free' && selectedTab.isSubscribed) {
      const confirmCancel = window.confirm('Do you want to cancel your current subscription?');
      if (confirmCancel) {
        await handleCancelSubscription();
      } else {
        return;
      }
    } else {
      setSelectedTab(prev => ({ ...prev, plan, price, tokens, isNewPlanSelected: plan !== prev.currentPlan }));
    }
  };
  
  const handleCancelSubscription = async () => {
    try {
      const userEmail = localStorage.getItem("userEmail");
      if (!userEmail) {
        Logger.error("User email not found in localStorage");
        return;
      }
      const response = await axios.post(`${CONFIG.BASE_URL}/cancel_subscription_website`, {
        userEmail
      });
      if (response.data.success) {
        alert('Your subscription has been canceled.');
        // Update the state accordingly
        setSelectedTab(prev => ({
          ...prev,
          isSubscribed: false,
          currentPlan: 'Free',
          plan: 'Free',
          isNewPlanSelected: false
        }));
        setAutoRenew(false); // Disable auto-renew
      } else {
        alert('Failed to cancel subscription.');
      }
    } catch (error) {
      Logger.error('Error canceling subscription:', error);
      alert('An error occurred while canceling your subscription.');
    }
  };
  

  useEffect(() => {
    // Check if the user is logged in
    const isLoggedIn = sessionStorage.getItem("isLoggedIn"); // Adjust this according to your auth logic

    if (!userEmail) {
      navigate("/login"); // Redirect to login page if not logged in
    }
  }, [navigate]);

  useEffect(() => {
    if (userEmail) {
      fetchSubscription(userEmail, setSelectedTab, setAutoRenew);
      fetchiosSubscription(userEmail);
    }


  }, [userEmail, setSelectedTab, setAutoRenew]);

  useEffect(() => {
    const updateCredits = async () => {
      
      const credits = await fetchUserCredits();

      setUserCredits(credits);


    };

    if (localStorage.getItem("userEmail")) {
      updateCredits();
    } else {
      Logger.debug("User Email not found, can't fetch credits.");
    }
  }, []);


  const handleAutoRenewToggle = async (
    newAutoRenewState: boolean,
    userEmail: string | null,
    setAutoRenew: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!userEmail) {
      Logger.error("User email is null, cannot update auto-renew");
      return;
    }
  
    setAutoRenew(newAutoRenewState);  // Update the local state
  
    try {
      const response = await axios.post(`${CONFIG.BASE_URL}/autorenew`, {
        userEmail,
        autoRenew: newAutoRenewState
      });
  
      if (!response.data.success) {
        throw new Error('Failed to update auto-renew');
      }
      Logger.debug("Auto-renew updated successfully");
    } catch (error) {
      Logger.error('Error updating auto-renew:', error);
      setAutoRenew(!newAutoRenewState); // Revert to previous state on error
    }
  };
  

  const handleBuyPlan = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const path = "/checkout";
    if (selectedTab.isSubscribed && !selectedTab.isNewPlanSelected) {
      alert('Please select a different plan to upgrade.');
      return;
    }

    if (isIOSSubscription) {
      alert("You subscribed via the App Store. Please manage your subscription in the App Store or cancel it there before making changes.");
      return;
    }
    // Map to the backend plan identifier here
    const backendPlan = planMapping[selectedTab.plan] || selectedTab.plan;
    const updatedSelectedTab = { ...selectedTab, plan: backendPlan };
    
    navigate(path, { state: { selectedTab: updatedSelectedTab } });
    // navigate(path, { state: { updatedSelectedTab } });
  };
  
  //Event handler using imported function
  const toggleAutoRenew = (event: ChangeEvent<HTMLInputElement>) => {
    handleAutoRenewToggle(event.target.checked, userEmail, setAutoRenew);
  };

  // const buttonText = selectedTab.isSubscribed ? 'Change Plan' : 'Purchase Plan';


  return (

    <div className="buy-credit">


  <div className="top-info">
  
  <div className="current-plan" >
    {/* Current Plan: {selectedTab.currentPlan || 'No Plan Selected'}
    {userCredits !== null ? ` - Your Credits: ${userCredits}` : ''} */}
    {/* <p>Credit Logic: each chatbot response will use one credit.</p> */}

  </div>
  
  {!isIOSSubscription && (
  <div className="auto-renew-toggle">
        <span>Auto Renew Plan:</span>
        <Switch 
  checked={autoRenew}
  onChange={(event) => {
    if (userEmail) {
      handleAutoRenewToggle(event.target.checked, userEmail, setAutoRenew);
    } else {
      Logger.error("User email is not available.");
    }
  }}
/>
      </div>
       )}
  </div>

  <div className="buy-credit-content">

    <div className="tab-buttons">
      <div className="pricing-container">
        {[
          { name: 'Basic', price: '$5', credits: 400, color: "#79ccd5",  description: "Basic plan allows you to send up to 400 text messages or about 3 hours of voice chat with Psyfy each month. Ideal for casual users who interact occasionally with Psyfy."},
          { name: 'Plus', price: '$11', credits: 800, color: "#e7c30b", description: "The Plus plan offers up to 800 text messages or around 7 hours of voice chat per month. Suitable for more regular use and extended interactions with Psyfy."},
          { name: 'Premium', price: '$20', credits: 2000, color: '#C785FD', description: "The Premium plan unlocks the full Psyfy experience with up to 2000 text messages or 20 hours of voice chat each month. Best for frequent users."},
          { name: 'Free', price: '$0', credits: 0, color: 'white', description: "The Free plan includes up to 40 text messages or about 20 mins of voice chat and it resets each month"}
        ].map(plan => (
          <button key={plan.name} 
                  className={`${plan.name.toLowerCase()} ${selectedTab.plan === plan.name ? "active" : ""} ${selectedTab.currentPlan === plan.name ? "deactivated" : ""}`}
                  onClick={() => handleTabClick(plan.name, plan.price, plan.credits)}
                  disabled={selectedTab.currentPlan === plan.name}>
            <h1 style={{ color: plan.color }}>{plan.name}</h1>
            <div className="pricing" style={{ color: plan.color }}>{plan.price}</div>
            <div className="month">/month</div>
            <div className="description" style={{ color: "#ffffff", marginTop: "10px" }}>{plan.description}</div>
            {/* <div className="tokens">{plan.credits} credits</div> */}

          </button>
        ))}


        </div>

        {isIOSSubscription ? (
          <div className="ios-subscription-message">
              <p>You have subscribed to a plan through the App Store. Please manage or cancel your subscription via the App Store before making changes here.</p>
          </div>
      ) : (
          <button className="buy-credit-button" onClick={handleBuyPlan} disabled={selectedTab.isSubscribed && !selectedTab.isNewPlanSelected}>
           
            
              {selectedTab.isSubscribed ? 'Change Plan' : 'Purchase Plan'}
          </button>
      )}
        
      {/* <button className="buy-credit-button" onClick={handleBuyPlan} disabled={selectedTab.isSubscribed && !selectedTab.isNewPlanSelected}>
      {isIOSSubscription ? "Manage on App Store" : selectedTab.isSubscribed ? 'Change Plan' : 'Purchase Plan'}

      </button> */}

      
    </div>
  </div>
</div>
 

 
  );
};

export default BuyCredit;



