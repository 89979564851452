import { useState, useEffect } from "react";

export function useUserAgreement(defaultValue) {
  const [hasAgreed, setHasAgreed] = useState(
    () => JSON.parse(sessionStorage.getItem("hasAgreed")) || defaultValue
  );

  useEffect(() => {
    sessionStorage.setItem("hasAgreed", JSON.stringify(hasAgreed));
  }, [hasAgreed]);

  return [hasAgreed, setHasAgreed];
}
