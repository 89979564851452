class Logger {
    static logLevel = 'error'; // Default log level
  
    static levels = {
      debug: 0,
      info: 1,
      warn: 2,
      error: 3
    };
  
    static setLogLevel(level) {
      Logger.logLevel = level;
    }
  
    static debug(message, ...params) {
      if (Logger.levels[Logger.logLevel] <= Logger.levels.debug) {
        console.debug(message, ...params);
      }
    }
  
    static info(message, ...params) {
      if (Logger.levels[Logger.logLevel] <= Logger.levels.info) {
        console.log(message, ...params);
      }
    }
  
    static warn(message, ...params) {
      if (Logger.levels[Logger.logLevel] <= Logger.levels.warn) {
        console.warn(message, ...params);
      }
    }
  
    static error(message, ...params) {
      if (Logger.levels[Logger.logLevel] <= Logger.levels.error) {
        console.error(message, ...params);
      }
    }
  }
  
  export default Logger;

  