import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermsAndConditions from "./TermsContent";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const TermsConditionPage = () => {
  return (
    <div>

      <TermsAndConditions />

    </div>
  );
};

export default TermsConditionPage;
