import React from "react";
import SignUpForm from "./SignUpForm";
import "../login/login.scss";
import "./signup.scss"

interface LocationData {
  country?: string; 
}
const SignUp = () => {


  return (
   
      <div className="signup-header">

        <div className="login-page-container">
        <div className="form-and-chat">
          <SignUpForm />
        </div>
      </div>

    </div>
  );
};

export default SignUp;
