import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { sendAgreeData } from "../../hooks/chatbotService";
import Logger from "../../utils/logger"
import {usePsyfyClient} from "../../hooks/psyfyClient";


const AgreementComponent = ({ onAgree }) => {
  const [dataConsent, setDataConsent] = useState(true);
  const [agreeToChat, setAgreeToChat] = useState(false); 
  const {  fetchUserLanguageFromBackend } = usePsyfyClient();
  const [language, setLanguage] = useState("english"); 

   // Fetch user language on load
   useEffect(() => {
    const fetchLanguage = async () => {
      const storedLanguage = localStorage.getItem("language");
      console.log("storedLanguage", storedLanguage);
      if (!storedLanguage) {
        try {
          // Fetch user language from the backend
          const userLanguage = await fetchUserLanguageFromBackend();
          if (userLanguage) {
            setLanguage(userLanguage); // Set language from backend
            localStorage.setItem("language", userLanguage); // Save to localStorage
          }
        } catch (error) {
          console.error("Error fetching language from backend:", error);
        }
      } else {
        // If language is already stored, use the stored value
        setLanguage(storedLanguage);
      }
    };

    fetchLanguage();
  }, []);


  const handleDataConsentChange = async (e) => {
    const isChecked = e.target.checked;
    setDataConsent(isChecked);

    try {
      await sendAgreeData(isChecked);
      Logger.debug("Data consent sent to the backend:", isChecked);
    } catch (error) {
      Logger.error("Error sending data to the backend:", error);
    }
  };



  const handleAgree = async () => {
    setAgreeToChat(true); 

    try {
      // This should be valid because handleAgree is an async function
      await sendAgreeData(dataConsent,  true);
      Logger.debug("Data consent sent to the backend:", dataConsent,  true);

      // If sending the data consent is successful
      onAgree();
    } catch (error) {
      Logger.error("Error sending data to the backend:", error);
      // Optionally handle the error, perhaps show a message to the user
    }
  };


  const agreementText = language === "chinese" ? (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "20px"}}>欢迎使用 Psyfy！</h2>
      <p>我们很高兴你加入我们。在开始之前，请查看我们的 <Link to="/terms">条款和条件</Link>。</p>
      <p>点击"同意"即表示你理解并同意以下内容：</p>
      <ul className="indented-list">
        <li>AI可能生成各种回复，包括一些可能较为敏感或具争议性的内容。</li>
        <li>你理解并同意本网站及其创建者对AI的言论不承担任何责任。</li>
        <li>我们通过加密保护你的数据，但无法保证其绝对安全。我们不会将你的信息出售给第三方。我们目前使用的第三方服务包括OpenAI，你的数据可能会被OpenAI处理。</li>
      </ul>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="data-consent"
          checked={dataConsent}
          onChange={handleDataConsentChange}
        />
        <label htmlFor="data-consent">
          我愿意提供我的数据来改进Psyfy的服务。查看我们的 <Link to="/privacy">隐私政策</Link>
        </label>
      </div>
    </>
  ) : (
    <>
      <h2 style={{ textAlign: "center" }}>Welcome to Psyfy!</h2>
      <p>We're excited to have you here. Before we begin, please review our <Link to="/terms">terms and conditions</Link>.</p>
      <p>By clicking "Agree," you acknowledge that:</p>
      <ul className="indented-list">
        <li>The AI may generate a wide range of responses, including some that could be potentially sensitive or controversial.</li>
        <li>You understand and agree that this website and its creators cannot be held responsible for the AI's statements.</li>
        <li>We strive to protect your data through encryption but cannot guarantee its absolute security. Rest assured, we do not sell your information to third parties. Currently, the third-party services we use include OpenAI, and your data may be processed by OpenAI. We kindly ask you to ensure that you are comfortable with this before proceeding.</li>
      </ul>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="data-consent"
          checked={dataConsent}
          onChange={handleDataConsentChange}
        />
        <label htmlFor="data-consent">
          I would like to contribute my data to improving Psyfy's service.
          Review our <Link to="/privacy">Privacy Policy</Link>
        </label>
      </div>
    </>
  );


  return (
    <div className="agree-tacbox" id="tacbox1">
       <label htmlFor="button1">
        {agreementText}
        <div style={{ height: "2vh" }}></div>
      </label>
      {/* <label htmlFor="button1">
        <h2 style={{ textAlign: "center" }}>Welcome to Psyfy!</h2>
        <br></br>
        <p>
          We're excited to have you here. Before we begin, we kindly ask you to
          review our <Link to="/terms">terms and conditions</Link> if you
          haven't already done so.
        </p>{" "}
        <br></br>
        <p>By clicking "Agree," you acknowledge that:</p> <br></br>
        <ul className="indented-list">
          <li>
            The AI may generate a wide range of responses, including some that
            could be potentially sensitive or controversial.
          </li>
          <br></br>
          <li>
            You understand and agree that this website and its creators cannot
            be held responsible for the AI's statements.
          </li>

          <br></br>
          <li>
            We strive to protect your data through encryption but cannot
            guarantee its absolute security. Rest assured, we do not sell your
            information to third parties.  Currently, the third-party services we use include OpenAI, and your data may be processed by OpenAI. We kindly ask you to ensure that you are comfortable with this before proceeding.
          </li>
        </ul>
        <br></br>
        <div className="checkbox-container">
          {/* <input type="checkbox" id="data-consent" /> */}
          {/* <input
            type="checkbox"
            id="data-consent"
            checked={dataConsent}
            onChange={handleDataConsentChange}
          />
          <label htmlFor="data-consent">
            I would like to contribute my data to improving Psyfy's service.
            Review our <Link to="/privacy">Privacy Policy</Link>
          </label>
        </div>
        <div style={{ height: "2vh" }}></div>
      </label> */} 

      <div className="agree-btn-container">
        <button onClick={handleAgree} className="agree-btn">
        {language === "chinese" ? "同意" : "Agree"}
          {/* Agree */}
        </button>
      </div>
    </div>
  );
};

export default AgreementComponent;
