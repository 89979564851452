import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./PrivacyContent";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";

const PrivacyPolicyPage = () => {
  return (
    <div>

      <PrivacyPolicy />

    </div>
  );
};

export default PrivacyPolicyPage;
